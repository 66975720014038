import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

function CloudSolutionsHero() {
  const data = useStaticQuery(graphql`
    {
      mobileBackground: file(
        relativePath: { eq: "mobile/company-hero-bg.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      desktopBackground: file(relativePath: { eq: "company-hero-bg.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      googleCloud: file(relativePath: { eq: "google-cloud-partner.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  `)

  return (
    <div className="py-24 relative overflow-hidden">
      <div className="absolute mx-auto w-full max-w-screen-2xl left-0 top-0 right-0 items-center lg:items-start">
        <div className="ml-auto w-1/3 h-auto lg:hidden">
          <GatsbyImage
            alt=""
            image={data.mobileBackground.childImageSharp.gatsbyImageData}
          />
        </div>
        <div className="ml-auto w-1/2 h-auto hidden lg:block">
          <GatsbyImage
            alt=""
            image={data.desktopBackground.childImageSharp.gatsbyImageData}
          />
        </div>
      </div>
      <div className="container relative">
        <div className="max-w-screen-lg">
          <h1 className="font-medium text-4.5xl md:text-5xl lg:text-6xl leading-tight text-gray-900">
            Accelerate your digital business
          </h1>
          <p className="py-3 md:pb-5 font-medium text-base md:text-2xl text-medium text-gray-700 leading-6 md:leading-8">
            Digital transformation is all built on data. Whether you are trying
            to evolve your customer experience, bring products to market quicker
            or make people more productive.
          </p>
          <div className="md:pb-10">
            <a
              href="https://cloud.withgoogle.com/partners/detail/?id=new-media-communications&hl=en-US"
              target="_blank"
              rel="noopener noreferrer"
            >
              <GatsbyImage
                image={data.googleCloud.childImageSharp.gatsbyImageData}
                className="h-auto w-40 md:w-48 block"
                alt="Google Cloud Partner"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CloudSolutionsHero