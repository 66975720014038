import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Compiting1 from "../svg/cloud-solutions/compiting-1.svg"
import Compiting2 from "../svg/cloud-solutions/compiting-2.svg"
import Compiting3 from "../svg/cloud-solutions/compiting-3.svg"
import Compiting4 from "../svg/cloud-solutions/compiting-4.svg"
import DataAnalytics1 from "../svg/cloud-solutions/data-analytics-1.svg"
import DataAnalytics2 from "../svg/cloud-solutions/data-analytics-2.svg"
import DataAnalytics3 from "../svg/cloud-solutions/data-analytics-3.svg"
import DataAnalytics4 from "../svg/cloud-solutions/data-analytics-4.svg"
import Security1 from "../svg/cloud-solutions/security-1.svg"
import Security2 from "../svg/cloud-solutions/security-2.svg"
import Security3 from "../svg/cloud-solutions/security-3.svg"
import Monitoring1 from "../svg/cloud-solutions/monitoring-1.svg"
import Monitoring2 from "../svg/cloud-solutions/monitoring-2.svg"
import Databases1 from "../svg/cloud-solutions/databases-1.svg"
import Databases2 from "../svg/cloud-solutions/databases-2.svg"
import Databases3 from "../svg/cloud-solutions/databases-3.svg"
import Databases4 from "../svg/cloud-solutions/databases-4.svg"
import Storage1 from "../svg/cloud-solutions/storage-1.svg"
import Storage2 from "../svg/cloud-solutions/storage-2.svg"
import Networking1 from "../svg/cloud-solutions/networking-1.svg"
import Networking2 from "../svg/cloud-solutions/networking-2.svg"
import Networking3 from "../svg/cloud-solutions/networking-3.svg"
import Networking4 from "../svg/cloud-solutions/networking-4.svg"
import MachineLearning1 from "../svg/cloud-solutions/ml-1.svg"
import MachineLearning2 from "../svg/cloud-solutions/ml-2.svg"
import MachineLearning3 from "../svg/cloud-solutions/ml-3.svg"

const icons = {
  "compiting-1": Compiting1,
  "compiting-2": Compiting2,
  "compiting-3": Compiting3,
  "compiting-4": Compiting4,
  "data-analytics-1": DataAnalytics1,
  "data-analytics-2": DataAnalytics2,
  "data-analytics-3": DataAnalytics3,
  "data-analytics-4": DataAnalytics4,
  "security-1": Security1,
  "security-2": Security2,
  "security-3": Security3,
  "monitoring-1": Monitoring1,
  "monitoring-2": Monitoring2,
  "databases-1": Databases1,
  "databases-2": Databases2,
  "databases-3": Databases3,
  "databases-4": Databases4,
  "storage-1": Storage1,
  "storage-2": Storage2,
  "networking-1": Networking1,
  "networking-2": Networking2,
  "networking-3": Networking3,
  "networking-4": Networking4,
  "ml-1": MachineLearning1,
  "ml-2": MachineLearning2,
  "ml-3": MachineLearning3,
}

function CloudSolutionsIconListing() {
  const data = useStaticQuery(graphql`
    query {
      allCloudSolutionJson {
        edges {
          node {
            id
            title
            iconNames
          }
        }
      }
    }
  `)

  return (
    <div className="bg-gray-100 py-5 md:py-8 lg:py-12">
      <div className="container">
        <div className="grid grid-cols-2 lg:grid-cols-4 gap-x-2 gap-y-6 md:gap-y-10 md:gap-x-10">
          {data &&
            data.allCloudSolutionJson.edges.map(({ node: item }) => {
              const { id, title, iconNames } = item
              return (
                <div key={id} className="flex flex-col">
                  <span className="text-lg lg:text-2xl">{title}</span>
                  <div className="flex flex-wrap">
                    {iconNames &&
                      iconNames.map((iconName, index) => {
                        const Icon = icons[iconName] || "div"
                        return (
                          <Icon
                            key={`${iconName}-${index}`}
                            className="block w-10 h-10 md:w-12 md:h-12 mr-2 mt-2 flex-shrink-0"
                          />
                        )
                      })}
                  </div>
                </div>
              )
            })}
        </div>
      </div>
    </div>
  )
}

export default CloudSolutionsIconListing
