import React from "react"

import Seo from "../components/seo"
import CloudSolutionsHero from "../components/CloudSolutionsHero"

import ConsultIcon from "../svg/cloud-solutions/consult.svg"
import BuildIcon from "../svg/cloud-solutions/build-deploy.svg"
import SuccessIcon from "../svg/cloud-solutions/success.svg"
import CloudSolutionsIconListing from "../components/CloudSolutionsIconListing"
import GetStarted from "../components/GetStarted"

const IconText = ({ iconName, title, description }) => {
  let Icon = null
  switch (iconName) {
    case "consult":
      Icon = ConsultIcon
      break
    case "build":
      Icon = BuildIcon
      break
    case "success":
      Icon = SuccessIcon
      break
    default:
      Icon = null
  }

  return (
    <div className="flex items-center mb-6 md:mb-8">
      {Icon && (
        <Icon className="block h-12 w-12 lg:h-20 lg:w-20 mr-4 md:mx-8 flex-shrink-0" />
      )}
      <span className="text-base md:text-lg lg:text-2xl text-black md:w-2/3">
        <span className="text-primary pr-2">{title}</span>
        {description}
      </span>
    </div>
  )
}

const TextSection = ({ title, description }) => {
  return (
    <div className="flex flex-col">
      <span className="text-2xl lg:text-4xl text-black">{title}</span>
      <p className="text-base lg:text-lg pt-1">{description}</p>
    </div>
  )
}

function CloudSolutionsPage() {
  return (
    <>
      <Seo
        title="Cloud Solutions"
        description=" We help organizations move from legacy systems to affordable and innovative cloud and mobile services. But we don’t stop there. Innovation is a continuous improvement cycle which requires ongoing engagement with our customer teams."
      />
      <CloudSolutionsHero />
      <div className="bg-gray-100 py-5 sm:py-8 lg:py-12">
        <div className="container flex flex-col">
          <p className="text-xl md:text-2xl lg:text-3.5xl font-medium text-gray-900">
            We help organizations move from legacy systems to affordable and
            innovative cloud and mobile services. But we don’t stop there.
            Innovation is a continuous improvement cycle which requires ongoing
            engagement with our customer teams.
          </p>
          <div className="flex flex-col mt-5 sm:mt-8 lg:mt-12">
            <IconText
              iconName="consult"
              title="Consult"
              description="Define vision and objectives,
              build business sponsorship, scope initial project horizons."
            />
            <IconText
              iconName="build"
              title="Build and Deploy"
              description="Prioritise, prototype and deploy services,
              engage and enable user groups."
            />
            <IconText
              iconName="success"
              title="Customer Success Deliver"
              description="process improvements, measure and reward success,
              promote a culture of innovation, define future product horizons."
            />
          </div>
        </div>
      </div>
      <div className="container flex flex-col py-16 md:py-20">
        <div className="flex flex-col">
          <span className="text-2xl lg:text-4xl text-black">
            Tailored tech solutions
          </span>
          <p className="text-lg lg:text-xl pt-1">
            At NMC Soft, we understand that every business is
            different. Every organisation has different ambitions and goals. We
            work closely with you to understand your objectives, so that we can
            help you transform your organisation and drive business growth with
            tailored cloud technology solutions.
          </p>
        </div>
        <div className="grid gird-cols-1 md:grid-cols-2 gap-y-5 md:gap-x-8 md:gap-y-6 lg:gap-x-16 lg:gap-y-12 pt-12">
          <TextSection
            title="Modernise your IT infrastructure"
            description="Achieve speed, security, and scalability with application modernisation, VM migration and high performance computing."
          />
          <TextSection
            title="Innovate with data"
            description="Get real-time insights on your organisation and customers. Implement large scale automation with data lakes, predictive analytics, and machine learning."
          />
          <TextSection
            title="Transform how you work"
            description="Drive productivity by enabling your entire workforce to create and collaborate anytime, anywhere."
          />
          <TextSection
            title="Application development"
            description="Give users the best experience and the latest technology with cutting-edge apps, built by award-winning developers."
          />
        </div>
      </div>
      <CloudSolutionsIconListing />
      <GetStarted />
    </>
  )
}

export default CloudSolutionsPage
